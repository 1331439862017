var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-2",attrs:{"to":{ name: 'categories-create' },"variant":"primary"}},[_vm._v(" Létrehozás ")])],1)])],1),_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Keresés")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Keresés","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.allCategories,"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      },"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.name))])]):_vm._e(),(props.column.field === 'parent.name')?_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.parent.name))])]):_vm._e(),(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                  name: 'categories-edit',
                  params: { id: props.row.id },
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Szerkesztés")])],1),_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteCategoryConfirm(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Törlés")])],1)],1)],1)]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Jelenleg ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3', '5', '10']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" találat a(z) "+_vm._s(props.total)+" -ból / ből ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }