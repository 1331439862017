<template>
  <div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :to="{ name: 'categories-create' }"
            class="ml-2"
            variant="primary"
          >
            Létrehozás
          </b-button>
        </div>
      </b-form-group>
    </div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Keresés</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Keresés"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <b-overlay :show="loading">
      <vue-good-table
        :columns="columns"
        :rows="allCategories"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'name'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>
          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'parent.name'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.parent.name }}</span>
          </div>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'categories-edit',
                    params: { id: props.row.id },
                  }"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Szerkesztés</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click.stop="deleteCategoryConfirm(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Törlés</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Jelenleg </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                találat a(z) {{ props.total }} -ból / ből
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BOverlay,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Név keresés',
          },
        },
        {
          label: 'Szülő',
          field: 'parent.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Szülő keresés',
          },
        },
        {
          label: 'Műveletek',
          field: 'action',
        },
      ],
      loading: false,
      searchTerm: '',
    }
  },
  methods: {
    ...mapActions(['fetchCategories', 'deleteCategory']),
    initCategories() {
      this.loading = true
      this.fetchCategories().then(res => {
        this.loading = false
      })
    },
    deleteCategoryConfirm(id) {
      this.$swal({
        title: 'Biztos vagy benne?',
        text: 'A rekord törlése nem visszavonható!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Törlés',
        cancelButtonText: 'Mégse',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteCategory(id)
          this.$swal({
            icon: 'success',
            title: 'Sikeres törlés!',
            text: 'A rekord törlése megtörtént',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
  computed: {
    ...mapGetters(['allCategories']),
  },
  created() {
    this.initCategories()
  },
}
</script>
